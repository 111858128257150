import { BankReportStatus } from "@/interfaces/applicationSettings";
import { VertinimasInterface } from "@/interfaces/vertinimai";

export enum soapActions {
  Prisijungti2 = "ns1:Prisijungti2",
  Prisijungti3 = "ns1:Prisijungti3",
  KeistiSlaptazodi = "ns1:KeistiSlaptazodi",
  GautiPranesimus = "ns1:GautiPranesimus",
  GautiSutarciuSarasa3 = "ns1:GautiSutarciuSarasa3",
  GautiVartotojus = "ns1:GautiVartotojus",
  GautiNaujaParaiska2 = "ns1:GautiNaujaParaiska2",
  IssaugotiParaiska2 = "ns1:IssaugotiParaiska2",
  TikrintiAsmensDuomenis = "ns1:TikrintiAsmensDuomenis",
  GautiSutarti2 = "ns1:GautiSutarti2",
  GautiSutikimoDokumenta2 = "ns1:GautiSutikimoDokumenta2",
  GautiSutikimoDokumentaSonT = "ns1:GautiSutikimoDokumentaSonT",
  GautiSutartiesDokumentaSonT = "ns1:GautiSutartiesDokumentaSonT",
  GautiSiulomusVariantus2 = "ns1:GautiSiulomusVariantus2",
  PerduotiPasirasytaSutikimoDokumenta2 = "ns1:PerduotiPasirasytaSutikimoDokumenta2",
  GautiSutartiesProjekta2 = "ns1:GautiSutartiesProjekta2",
  GautiVertinimoAtaskaitosPDF = "ns1:GautiVertinimoAtaskaitosPDF",
  GautiMokejimuGrafikoDokumentaAsmenini3 = "ns1:GautiMokejimuGrafikoDokumentaAsmenini3",
  GautiStandartinesInformacijosDokumenta2 = "ns1:GautiStandartinesInformacijosDokumenta2",
  SudarytiSutarti2 = "ns1:SudarytiSutarti2",
  GautiSutartiesDokumenta2 = "ns1:GautiSutartiesDokumenta2",
  AtmestiSutarti2 = "ns1:AtmestiSutarti2",
  PerimtiSutarti = "ns1:PerimtiSutarti",
  PerkeltiSutarti = "ns1:PerkeltiSutarti",
  IssaugotiPardaveja2 = "ns1:IssaugotiPardaveja2",
  GautiGalimasAtaskaitas = "ns1:GautiGalimasAtaskaitas",
  GautiAtaskaitosDuomenis = "ns1:GautiAtaskaitosDuomenis",
  GautiSalyguVariantus2 = "ns1:GautiSalyguVariantus2",
  GautiSalyguVariantusVadybininkui = "ns1:GautiSalyguVariantusVadybininkui",
  SuskaiciuotiMokejimuGrafika2 = "ns1:SuskaiciuotiMokejimuGrafika2",
  SuskaiciuotiMokejimuGrafikaAsmenini2 = "ns1:SuskaiciuotiMokejimuGrafikaAsmenini2",
  GautiMokejimuGrafikoDokumentaSuDiena = "ns1:GautiMokejimuGrafikoDokumentaSuDiena",
  AdresasAutocomplete = "ns1:AdresasAutocomplete",
  GautiParaiskosDokumentuSarasa = "ns1:GautiParaiskosDokumentuSarasa",
  IkeltiParaiskosDokumenta2 = "ns1:IkeltiParaiskosDokumenta2",
  IstrintiParaiskosDokumenta = "ns1:IstrintiParaiskosDokumenta",
  GautiParaiskosDokumenta = "ns1:GautiParaiskosDokumenta",
  TrintiParaiska = "ns1:TrintiParaiska",
  IssaugotiKlientoEmaila = "ns1:IssaugotiKlientoEmaila",
  GautiSutartiesDokumentaPasirasyta = "ns1:GautiSutartiesDokumentaPasirasyta",
  GautiSutikimoDokumentaPasirasyta = "ns1:GautiSutikimoDokumentaPasirasyta",
  SiustiDokumentus = "ns1:SiustiDokumentus",
  PatvirtintiPasirasyma = "ns1:PatvirtintiPasirasyma",
  PerduotiPasirasytaSutartiesDokumenta2 = "ns1:PerduotiPasirasytaSutartiesDokumenta2",
  PratestiIgaliojima = "ns1:PratestiIgaliojima",
  GautiSkoluAtaskaitosHTML2 = "ns1:GautiSkoluAtaskaitosHTML2",
  GautiParaiskosVeiksmuZurnala = "ns1:GautiParaiskosVeiksmuZurnala",
  InicijuotiSutuoktinioParaiskosPasirasyma = "ns1:InicijuotiSutuoktinioParaiskosPasirasyma",
  InicijuotiParaiskosPasirasyma = "ns1:InicijuotiParaiskosPasirasyma",
  InicijuotiPasirasyma = "ns1:InicijuotiPasirasyma",
  AtsauktiPasirasyma = "ns1:AtsauktiPasirasyma",
  GautiGarantijuDraudimoKainas = "ns1:GautiGarantijuDraudimoKainas",
  AtblokuotiVartotoja = "ns1:AtblokuotiVartotoja",
  NaujaMinimaliKreditoSuma = "ns1:NaujaMinimaliKreditoSuma",
  LeidimuSarasas = "ns1:LeidimuSarasas",
  LeidimaPatvirtinti = "ns1:LeidimaPatvirtinti",
  LeidimaAtmesti = "ns1:LeidimaAtmesti",
  LeidimaPrasyti = "ns1:LeidimaPrasyti",
  IsrasoStatusas = "ns1:IsrasoStatusas",
  IsrasaInicijuoti = "ns1:IsrasaInicijuoti",
  ServisuStatusas = "ns1:ServisuStatusas",
  GautiSBDSutikimoDokumenta = "ns1:GautiSBDSutikimoDokumenta",
  GautiPajamuSarasa = "ns1:GautiPajamuSarasa",
  IssaugotiPajamuSarasa = "ns1:IssaugotPajamuSarasa",
  TikrintiEmaila = "ns1:TikrintiEmaila",
  GautiSBDSutikimoDokumentaSonT = "ns1:GautiSBDSutikimoDokumentaSonT",
  PerduotiPasirasytaDraudimoDokumenta = "ns1:PerduotiPasirasytaDraudimoDokumenta",
  SutartisGalima2 = "ns1:SutartisGalima2",
  VertinimuSarasas = "ns1:VertinimuSarasas",
  VertinimoIsrasas = "ns1:VertinimoIsrasas",
}

export const SoapOK = "0";

export enum SoapErrors {
  MATCHES_OLD_PASSWORD = "1006",
  PASSWORD_TOO_OLD = "1009",
  INCORRECT_SESSION = "1020",
  INCORRECT_CONTRACT_STATUS = "1100",
  CLIENT_NAME_REQUIRED = "1030",
  EMPTY_LIST = "1310",
  NO_RESULTS_FOUND = "1900",
  SYSTEM_ERROR = "2000",
}

export interface ErrorResponseInterface {
  KlaidosKodas: number | string;
  KlaidosPranesimas: string;
  Statusas?: BankReportStatus;
}

export interface SoapBodyParams {
  [soapActions.Prisijungti2]?: Prisijungti2Interface;
}

export interface Prisijungti2Interface {
  "ns1:vartotojas": string;
  "ns1:slaptazodis": string;
}

export interface SoapBodyParams {
  [soapActions.Prisijungti3]?: Prisijungti3Interface;
}

export interface Prisijungti3Interface {
  "ns1:vartotojas": string;
  "ns1:slaptazodis": string;
  "ns1:ip": string | unknown;
}

export interface SoapBodyParams {
  [soapActions.GautiPranesimus]?: GautiPranesimusInterface;
}

export interface GautiPranesimusInterface {
  "ns1:sesija": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSutarciuSarasa3]?: GautiSutarciuSarasa3Interface;
}

export interface GautiSutarciuSarasa3Interface {
  "ns1:sesija": string;
  "ns1:nuo": string;
  "ns1:iki": string;
  "ns1:saskaita": string;
  "ns1:busena": number;
  "ns1:paraiskosNr": string;
  "ns1:preke": string;
  "ns1:pardavejas": string;
  "ns1:pirkejas": string;
  "ns1:ecreditVadybininkoStatusas": number;
}

export interface SoapBodyParams {
  [soapActions.GautiVartotojus]?: GautiVartotojusInterface;
}

export interface GautiVartotojusInterface {
  "ns1:sesija": string;
  "ns1:raktas"?: string;
  "ns1:stulpelis"?: "ParduotuvesPavadinimas" | string;
  "ns1:kryptis"?: "ASC" | "DESC" | string;
}

export interface SoapBodyParams {
  [soapActions.GautiNaujaParaiska2]?: GautiNaujaParaiska2Interface;
}

export interface GautiNaujaParaiska2Interface {
  "ns1:sesija": string;
  "ns1:pardavejoID": string;
}

export interface SoapBodyParams {
  [soapActions.IssaugotiParaiska2]?: IssaugotiParaiska2Interface;
}

export interface IssaugotiParaiska2Interface {
  "ns1:sesija": string;
  "ns1:paraiska": Paraiska2Interface;
}

export interface Paraiska2Interface {
  "ns1:ParaiskosID": string;
  "ns1:PardavejoID": string;
  "ns1:SutartiesNr": string;
  "ns1:PardavejoAtstovas": string;
  "ns1:Suma": number; //-1
  "ns1:DokumentoNr": string | null; // null
  "ns1:Trukme"?: number; //-1
  "ns1:SalyguVariantoID"?: number; //-1
  "ns1:AsmensKodas"?: string; //"00000000000"
  "ns1:Vardas"?: string | null; //null
  "ns1:Pavarde"?: string | null; // null
  "ns1:DokumentoTipoID"?: string | null; // null
  "ns1:SutuoktinioAsmensKodas"?: string;
  "ns1:SutuoktinioVardas"?: string;
  "ns1:SutuoktinioPavarde"?: string;
  "ns1:SutuoktinioDokumentoTipoID"?: string;
  "ns1:SutuoktinioDokumentoNr"?: string;
  "ns1:SutuoktinioDokumentoGaliojimoData"?: string;
  "ns1:Sutuoktinis"?: number;
  "ns1:Pajamos"?: number;
  "ns1:MazesPajamos"?: number;
  "ns1:BusimosPajamos"?: number;
  "ns1:SutuoktinioPajamos"?: number;
  "ns1:SutuoktinioMazesPajamos"?: number;
  "ns1:SutuoktinioBusimosPajamos"?: number;
  "ns1:KlientoVartojimoPaskola"?: number;
  "ns1:KlientoBustoPaskola"?: number;
  "ns1:Gatve"?: string;
  "ns1:Namas"?: string;
  "ns1:Butas"?: string;
  "ns1:Miestas"?: string;
  "ns1:Indeksas"?: string;
  "ns1:Telefonas"?: string;
  "ns1:Telefonas2"?: string;
  "ns1:SaskaitosNr"?: string;
  "ns1:MokejimoDiena"?: number;
  "ns1:Busena"?: number;
  "ns1:Data"?: string;
  "ns1:DokumentuVariantai"?: string | object;
  "ns1:SutuoktinioDokumentuVariantai"?: string | object;
  "ns1:DokumentoGaliojimoData"?: string;
  "ns1:SalyguVariantai"?: string | object;
  "ns1:MokejimuGrafikas"?: string | object;
  "ns1:Pranesimas"?: string;
  "ns1:Darboviete"?: string;
  "ns1:PasirasymoVieta"?: number;
  "ns1:KontrolineEilute"?: string;
  "ns1:ElPastas"?: string;
  "ns1:PajamuTipas"?: number;
  "ns1:Stazas"?: number;
  "ns1:TuriIsipareigojimu"?: number;
  "ns1:IsipareigojimuSuma"?: number;
  "ns1:PlanuojaIsipareigojimu"?: number;
  "ns1:PlanuojamuIsipareigojimuSuma"?: number;
  "ns1:KitosAplinkybes"?: number;
  "ns1:TuriVelavimu"?: number;
  "ns1:TuriIsieskojimu"?: number;
  "ns1:SeimyninePadetis"?: number;
  "ns1:SeimosNariuSkaicius"?: number;
  "ns1:IslaikytiniuSkaicius"?: number;
  "ns1:GyvenamosVietosTipas"?: number;
  "ns1:PajamuVariantai"?: string | object;
  "ns1:PiniguPanaudojimoVariantai"?: string | object;
  "ns1:Avansas"?: number;
  "ns1:AvansoTipas"?: number;
  "ns1:Valiuta"?: string;
  "ns1:PapildomasKodas"?: string;
  "ns1:KreditoLimitas"?: number;
  "ns1:MazosImokos"?: number;
  "ns1:PajamuPazyma"?: number;
  "ns1:KitiKreditai"?: number;
  "ns1:Pastabos"?: string;
  "ns1:Gatve2"?: string;
  "ns1:Namas2"?: string;
  "ns1:Butas2"?: string;
  "ns1:Miestas2"?: string;
  "ns1:Indeksas2"?: string;
  "ns1:TurtoRusys"?: string | object;
  "ns1:KlientoLizingoPaskola"?: number;
  "ns1:KlientoKitaPaskola"?: number;
  "ns1:VardasKorteleje"?: string;
  "ns1:AtsiemimoVietosID"?: number;
  "ns1:AtaskaitosTipas"?: number;
  "ns1:ParduotuvesPavadinimas"?: string;
  "ns1:SutartiesTipas"?: number;
  "ns1:SutuoktinioKontrolineEilute"?: string;
  "ns1:SutuoktinioPajamuTipas"?: number;
  "ns1:SutuoktinioDarboviete"?: string;
  "ns1:SutuoktinioPajamuPazyma"?: number;
  "ns1:SutuoktinioElPastas"?: string;
  "ns1:SutuoktinioTelefonas"?: string;
  "ns1:DraudimoImoka"?: number;
  "ns1:PatikslintaDraudimoImoka"?: number;
  "ns1:DraudimoID"?: number;
  "ns1:DraudimoPolisas"?: string;
  "ns1:Refinansavimas"?: string;
  "ns1:Kreditoriai"?: string | object;
  "ns1:PapildomaSaskaita"?: string;
  "ns1:Faktura"?: string;
  "ns1:SalyguVariantasSarasui"?: string;
  "ns1:PajamuTipasSarasui"?: string;
  "ns1:PapildomoKreditoSuma"?: number;
  "ns1:DuomenuPolitikosGavimas"?: number;
  "ns1:KreditoNaudojimas"?: number;
  "ns1:DalyvaujaPolitikoje"?: number;
  "ns1:PolitikoVardasPavarde"?: string;
  "ns1:PolitikoPareigos"?: string;
  "ns1:PolitikoRysys"?: string;
  "ns1:SutikimasPasiulymu"?: string;
  "ns1:SutikimasPartneriu"?: string;
  "ns1:SutikimasProfiliavimo"?: string;
  "ns1:SutuoktinioSutikimasPasiulymu"?: string;
  "ns1:SutuoktinioSutikimasPartneriu"?: string;
  "ns1:SutuoktinioSutikimasProfiliavimo"?: string;
  "ns1:ParaiskosPasirasymas"?: number;
  "ns1:SutartiesPasirasymas"?: number;
  "ns1:SutuoktinioParaiskosPasirasymas"?: number;
  "ns1:PiniguPanaudojimas"?: number;
  "ns1:Pilietybe"?: string;
  "ns1:SutuoktinioPilietybe"?: string;
  "ns1:TikrinimoData"?: string;
  "ns1:PapildomoKreditoPabaiga"?: string;
  "ns1:SonTGalimas"?: string;
  "ns1:InicijavimasGalimas"?: string;
  "ns1:PaskutinisPasirasymas"?: string;
  "ns1:Prekes"?: string | object;
  "ns1:ParaiskosPasirasymoData"?: string;
  "ns1:SutuoktinioParaiskosPasirasymoData"?: string;
  "ns1:SutartiesPasirasymoData"?: string;
  "ns1:EcreditSesijosID"?: number;
  "ns1:KatalogoAkcijosIsimtis"?: number;
}

export interface SoapBodyParams {
  [soapActions.TikrintiAsmensDuomenis]?: TikrintiAsmensDuomenisInterface;
}

export interface SoapBodyParams {
  [soapActions.GautiSkoluAtaskaitosHTML2]?: GautiSkoluAtaskaitosHTML2Interface;
}

export interface TikrintiAsmensDuomenisInterface {
  "ns1:sesija": string;
  "ns1:asmensKodas"?: string;
}

export interface SoapBodyParams {
  [soapActions.GautiSutarti2]?: GautiSutarti2Interface;
}

export interface SoapBodyParams {
  [soapActions.InicijuotiPasirasyma]?: GautiSutarti2Interface;
}

export interface SoapBodyParams {
  [soapActions.AtsauktiPasirasyma]?: GautiSutarti2Interface;
}

export interface GautiSutarti2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSutikimoDokumenta2]?: GautiSutikimoDokumenta2Interface;
}

export interface GautiSutikimoDokumenta2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:asmensKodas": string;
  "ns1:kieno": number;
}

export interface SoapBodyParams {
  [soapActions.GautiSutartiesDokumentaSonT]?: GautiSutartiesDokumentaSonTInterface;
}

export interface GautiSutartiesDokumentaSonTInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSutikimoDokumentaSonT]?: GautiSutikimoDokumentaSonTInterface;
}

export interface GautiSutikimoDokumentaSonTInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:asmensKodas": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSiulomusVariantus2]?: GautiSiulomusVariantus2Interface;
}

export interface SoapBodyParams {
  [soapActions.InicijuotiSutuoktinioParaiskosPasirasyma]?: GautiSiulomusVariantus2Interface;
}

export interface SoapBodyParams {
  [soapActions.InicijuotiParaiskosPasirasyma]?: GautiSiulomusVariantus2Interface;
}

export interface GautiSiulomusVariantus2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.PerduotiPasirasytaSutikimoDokumenta2]?: PerduotiPasirasytaSutikimoDokumenta2Interface;
}

export interface SoapBodyParams {
  [soapActions.PerduotiPasirasytaSutartiesDokumenta2]?: PerduotiPasirasytaSutikimoDokumenta2Interface;
}

export interface PerduotiPasirasytaSutikimoDokumenta2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:dokumentas": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSutartiesProjekta2]?: GautiSutartiesProjekta2Interface;
}

export interface GautiSutartiesProjekta2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.GautiVertinimoAtaskaitosPDF]?: GautiVertinimoAtaskaitosPDFInterface;
}

export interface GautiVertinimoAtaskaitosPDFInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:asmensKodas": string; // looks like this is not used, and every time buyer document is returned
}

export interface SoapBodyParams {
  [soapActions.GautiMokejimuGrafikoDokumentaAsmenini3]?: GautiMokejimuGrafikoDokumentaAsmenini3Interface;
}

export interface GautiMokejimuGrafikoDokumentaAsmenini3Interface {
  "ns1:sesija": string;
  "ns1:suma": number;
  "ns1:avansas": number;
  "ns1:trukme": number;
  "ns1:salyguVariantoID": number;
  "ns1:mokejimuDiena": number;
  "ns1:asmensKodas": number;
  "ns1:kopijuSkaicius": string;
  "ns1:sutuoktinioAsmensKodas": number;
  "ns1:sutuoktinis": number;
  "ns1:pajamos": number;
  "ns1:sutuoktinioPajamos": number;
}

export interface GautiSkoluAtaskaitosHTML2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:asmenskodas": string;
}

export interface SoapBodyParams {
  [soapActions.GautiStandartinesInformacijosDokumenta2]?: GautiStandartinesInformacijosDokumenta2Interface;
}

export interface LeidimuSarasasInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.LeidimuSarasas]?: LeidimuSarasasInterface;
}

export interface LeidimaPatvirtintiInterface {
  "ns1:sesija": string;
  "ns1:leidimoID": number;
  "ns1:komentaras": string;
}

export interface SoapBodyParams {
  [soapActions.LeidimaPatvirtinti]?: LeidimaPatvirtintiInterface;
}
export interface SoapBodyParams {
  [soapActions.LeidimaAtmesti]?: LeidimaPatvirtintiInterface;
}

export interface LeidimaPrasytiInterface {
  "ns1:sesija": string;
  "ns1:paraiska": Paraiska2Interface;
  "ns1:komentaras": string;
}

export interface SoapBodyParams {
  [soapActions.LeidimaPrasyti]?: LeidimaPrasytiInterface;
}

export interface GautiStandartinesInformacijosDokumenta2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:kieno": number;
}

export interface SoapBodyParams {
  [soapActions.SudarytiSutarti2]?: SudarytiSutarti2Interface;
}

export interface SudarytiSutarti2Interface {
  "ns1:sesija": string;
  "ns1:paraiska": Paraiska2Interface;
}

export interface SoapBodyParams {
  [soapActions.GautiSutartiesDokumenta2]?: GautiSutartiesDokumenta2Interface;
}

export interface GautiSutartiesDokumenta2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:standartinesInfoKopijuSkaicius": number;
  "ns1:sutartiesKopijuSkaicius": number;
  "ns1:salyguKopijuSkaicius": number;
  "ns1:kieno": number;
  "ns1:rikiavimas": number;
}

export interface SoapBodyParams {
  [soapActions.AtmestiSutarti2]?: AtmestiSutarti2Interface;
}

export interface AtmestiSutarti2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.PerimtiSutarti]?: PerimtiSutartiInterface;
}

export interface PerimtiSutartiInterface {
  "ns1:sesija": string;
  "ns1:raktas": string;
}

export interface SoapBodyParams {
  [soapActions.PerkeltiSutarti]?: PerkeltiSutartiInterface;
}

export interface PerkeltiSutartiInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:kriterijai": KriterijusInterface[];
  "ns1:komentaras": string;
}

export interface KriterijusInterface {
  "ns1:KriterijausID": number;
  "ns1:KriterijausTipoID": number;
  "ns1:Pavadinimas": string;
  "ns1:Reiksme": number;
}

export interface SoapBodyParams {
  [soapActions.IssaugotiPardaveja2]?: IssaugotiPardavejaInterface;
}

export interface IssaugotiPardavejaInterface {
  "ns1:sesija": string;
  "ns1:pardavejoID": number;
  "ns1:parduotuvesID": number;
  "ns1:loginas": string;
  "ns1:vardas": string;
  "ns1:pavarde": string;
  "ns1:emailas": string;
  "ns1:aktyvus": number;
  "ns1:mobilus"?: string;
  "ns1:emailasPatvirtintas": number;
  "ns1:telefonasPatvirtintas": number;
  "ns1:autentifikacija": number;
  "ns1:slaptazodis"?: string;
}

export interface SoapBodyParams {
  [soapActions.GautiGalimasAtaskaitas]?: GautiGalimasAtaskaitasInterface;
}

export interface GautiGalimasAtaskaitasInterface {
  "ns1:sesija": string;
}

export interface SoapBodyParams {
  [soapActions.GautiAtaskaitosDuomenis]?: GautiAtaskaitosDuomenisInterface;
}

export interface GautiAtaskaitosDuomenisInterface {
  "ns1:sesija": string;
  "ns1:ataskaitosID": number;
  "ns1:parametrai"?: GautiAtaskaitosDuomenisParametraiWrapperInterface;
}

export interface GautiAtaskaitosDuomenisParametraiWrapperInterface {
  "ns1:ParametroReiksme": GautiAtaskaitosDuomenisParametraiInterface[];
}

export interface GautiAtaskaitosDuomenisParametraiInterface {
  "ns1:ParametroReiksmesID": string | number;
  "ns1:RodomaReiksme": string | number;
}

export interface SoapBodyParams {
  [soapActions.GautiSalyguVariantus2]?: GautiSalyguVariantus2Interface;
}

export interface GautiSalyguVariantus2Interface {
  "ns1:sesija": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSalyguVariantusVadybininkui]?: GautiSalyguVariantusVadybininkuiInterface;
}

export interface GautiSalyguVariantusVadybininkuiInterface {
  "ns1:sesija": string;
  "ns1:pardavejoID": number;
}

export interface SoapBodyParams {
  [soapActions.SuskaiciuotiMokejimuGrafika2]?: SuskaiciuotiMokejimuGrafika2Interface;
}

export interface SuskaiciuotiMokejimuGrafika2Interface {
  "ns1:sesija": string;
  "ns1:suma": number;
  "ns1:trukme": number;
  "ns1:salyguVariantoID": number;
}

export interface SoapBodyParams {
  [soapActions.SuskaiciuotiMokejimuGrafikaAsmenini2]?: SuskaiciuotiMokejimuGrafikaAsmenini2Interface;
}

export interface SuskaiciuotiMokejimuGrafikaAsmenini2Interface {
  "ns1:sesija": string;
  "ns1:suma": number;
  "ns1:trukme": number;
  "ns1:salyguVariantoID": number;
  "ns1:seimyninePadetis": number;
  "ns1:klientoVartojimoPaskola": number;
  "ns1:klientoBustoPaskola": number;
  "ns1:gimimoData"?: string;
  "ns1:seimosNariuSkaicius": number;
  "ns1:pajamos": number;
  "ns1:sutuoktinioPajamos": number;
}

export interface SoapBodyParams {
  [soapActions.GautiMokejimuGrafikoDokumentaSuDiena]?: GautiMokejimuGrafikoDokumentaSuDienaInterface;
}

export interface GautiMokejimuGrafikoDokumentaSuDienaInterface {
  "ns1:sesija": string;
  "ns1:suma": number;
  "ns1:trukme": number;
  "ns1:salyguVariantoID": number;
  "ns1:mokejimuDiena": number;
  "ns1:kopijuSkaicius": number;
}

export interface SoapBodyParams {
  [soapActions.AdresasAutocomplete]?: AdresasAutocompleteInterface;
}

export interface AdresasAutocompleteResponse {
  Variantai: { string: string | string[] };
}

export interface AdresasAutocompleteInterface {
  "ns1:sesija": string;
  "ns1:miestas": string;
  "ns1:gatve": string;
  "ns1:namas": string;
  "ns1:pastoIndeksas": number;
}

export interface SoapBodyParams {
  [soapActions.GautiGarantijuDraudimoKainas]?: GautiGarantijuDraudimoKainasInterface;
}

export interface GautiGarantijuDraudimoKainasInterface {
  "ns1:sesija": string;
}

export interface SoapBodyParams {
  // this is moved to userVerify microservice
  [soapActions.KeistiSlaptazodi]?: KeistiSlaptazodiInterface;
}

export interface KeistiSlaptazodiInterface {
  // this is moved to userVerify microservice
  "ns1:kodas": string;
  //  "ns1:loginas?": string; doesnt need when passing session in "ns1:kodas" field
  "ns1:slaptazodis": string;
}

export interface SoapBodyParams {
  [soapActions.GautiParaiskosDokumentuSarasa]?: GautiParaiskosDokumentuSarasaInterface;
}

export interface GautiParaiskosDokumentuSarasaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.IkeltiParaiskosDokumenta2]?: IkeltiParaiskosDokumenta2Interface;
}

export interface IkeltiParaiskosDokumenta2Interface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:dokumentoTipas": number;
  "ns1:turinys": string; // base64Binary
  "ns1:bylosPletinys": string;
}

export interface SoapBodyParams {
  [soapActions.IstrintiParaiskosDokumenta]?: IstrintiParaiskosDokumentaInterface;
}

export interface IstrintiParaiskosDokumentaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:dokumentoID": number;
}
export interface SoapBodyParams {
  [soapActions.GautiParaiskosDokumenta]?: GautiParaiskosDokumentaInterface;
}

export interface GautiParaiskosDokumentaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:dokumentoID": number;
}

export interface SoapBodyParams {
  [soapActions.TrintiParaiska]?: TrintiParaiskaInterface;
}

export interface TrintiParaiskaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.IssaugotiKlientoEmaila]?: IssaugotiKlientoEmailaInterface;
}

export interface IssaugotiKlientoEmailaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:emailas": string;
  "ns1:sutuoktinio": number; // 0 - client, 1 - spouse
}

export interface SoapBodyParams {
  [soapActions.GautiSutartiesDokumentaPasirasyta]?: GautiSutartiesDokumentaPasirasytaInterface;
}

export interface GautiSutartiesDokumentaPasirasytaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.GautiSutikimoDokumentaPasirasyta]?: GautiSutikimoDokumentaPasirasytaInterface;
}

export interface GautiSutikimoDokumentaPasirasytaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:asmensKodas": string;
}

export interface SoapBodyParams {
  [soapActions.SiustiDokumentus]?: SiustiDokumentusInterface;
}

export interface SiustiDokumentusInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.PatvirtintiPasirasyma]?: PatvirtintiPasirasymaInterface;
}

export interface PatvirtintiPasirasymaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.PratestiIgaliojima]?: PratestiIgaliojimaInterface;
}

export interface PratestiIgaliojimaInterface {
  "ns1:sesija": string;
}
export interface SoapBodyParams {
  [soapActions.GautiParaiskosVeiksmuZurnala]?: GautiParaiskosVeiksmuZurnalaInterface;
}

export interface GautiParaiskosVeiksmuZurnalaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
}

export interface SoapBodyParams {
  [soapActions.AtblokuotiVartotoja]?: AtblokuotiVartotojaInterface;
}

export interface AtblokuotiVartotojaInterface {
  "ns1:sesija": string;
  "ns1:loginas": string;
}

export interface SoapBodyParams {
  [soapActions.NaujaMinimaliKreditoSuma]?: NaujaMinimaliKreditoSumaInterface;
}

export interface NaujaMinimaliKreditoSumaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:senaKreditoSuma": number;
  "ns1:naujaKreditoSuma": number;
  "ns1:senaDraudimoImoka": number;
}

export interface SoapBodyParams {
  [soapActions.IsrasoStatusas]?: IsrasoStatusasInterface;
}

export interface SoapBodyParams {
  [soapActions.IsrasaInicijuoti]?: IsrasoStatusasInterface;
}

export interface IsrasoStatusasInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": number;
  "ns1:asmensKodas": string;
}

export interface SoapBodyParams {
  [soapActions.ServisuStatusas]?: ServisuStatusasInterface;
}

export interface ServisuStatusasInterface {
  "ns1:sesija": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSBDSutikimoDokumenta]?: GautiSBDSutikimoDokumentaInterface;
}

export interface GautiSBDSutikimoDokumentaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.GautiPajamuSarasa]?: GautiPajamuSarasaInterface;
}

export interface GautiPajamuSarasaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:sutuoktinis"?: number;
}

export interface SoapBodyParams {
  [soapActions.IssaugotiPajamuSarasa]?: IssaugotiPajamuSarasaInterface;
}

export interface IssaugotiPajamuSarasaIrasoInterfaceWrapperInterface {
  "ns1:Pajama": IssaugotiPajamuSarasaIrasoInterface[];
}

export interface IssaugotiPajamuSarasaIrasoInterface {
  "ns1:Pavadinimas": string;
  "ns1:DataNuo": string;
  "ns1:DataIki": string;
  "ns1:Saltinis": number;
  "ns1:PajamuSuma": number;
  "ns1:PatikslintaSuma": number;
  "ns1:Tikslinta": number;
  "ns1:Sutuoktinis": number;
  "ns1:TrintiEilute": number;
  "ns1:PajamosID": number | null;
  "ns1:PajamuRusiesID": number;
}
export interface IssaugotiPajamuSarasaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:pajamos": IssaugotiPajamuSarasaIrasoInterfaceWrapperInterface | null;
}

export interface SoapBodyParams {
  [soapActions.TikrintiEmaila]?: TikrintiEmailaInterface;
}

export interface TikrintiEmailaInterface {
  "ns1:sesija": string;
  "ns1:elPastas": string;
}

export interface SoapBodyParams {
  [soapActions.GautiSBDSutikimoDokumentaSonT]?: GautiSBDSutikimoDokumentaSonTInterface;
}

export interface GautiSBDSutikimoDokumentaSonTInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
}

export interface SoapBodyParams {
  [soapActions.PerduotiPasirasytaDraudimoDokumenta]?: PerduotiPasirasytaDraudimoDokumentaInterface;
}

export interface PerduotiPasirasytaDraudimoDokumentaInterface {
  "ns1:sesija": string;
  "ns1:paraiskosID": string;
  "ns1:dokumentas": string;
}

export interface SoapBodyParams {
  [soapActions.SutartisGalima2]?: SutartisGalima2Interface;
}

export interface SutartisGalima2Interface {
  "ns1:sesija": string;
  "ns1:loginas": string;
  "ns1:salyguVariantoID": number;
  "ns1:suma": number;
  "ns1:trukme": number;
  "ns1:vardas": string;
  "ns1:pavarde": string;
  "ns1:darboRezimas": number;
}

export interface SoapBodyParams {
  [soapActions.VertinimuSarasas]?: VertinimuSarasasInterface;
}

export interface VertinimuSarasasInterface {
  "ns1:sesija": string;
  "ns1:asmensKodas": string;
}

export interface VertinimuSarasasResponseInterface {
  KlaidosKodas: string; // "0"
  KlaidosPranesimas: string; // "null"
  Vertinimai?: VertinimoEilute;
}

export interface VertinimoEilute {
  VertinimoEilute: VertinimasInterface[] | VertinimasInterface;
}

export interface SoapBodyParams {
  [soapActions.VertinimoIsrasas]?: VertinimoIsrasasInterface;
}

export interface VertinimoIsrasasInterface {
  "ns1:sesija": string;
  "ns1:vertinimoID": number;
  "ns1:asmensKodas": string;
}

export interface IsrasoEiluteWrapperInterface {
  IsrasoEilute: IsrasoEiluteInterface[];
}
export interface IsrasoEiluteInterface {
  IsrasoID: number;
  EilesNr: number;
  Salyga: string;
  Rezultatas: string;
  Galima: number;
}

export interface VertinimoIsrasasResponseInterface {
  Eilutes: IsrasoEiluteWrapperInterface;
  KlaidosKodas: number;
  KlaidosPranesimas: string;
}
